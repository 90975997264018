import { useEffect } from 'react';
import { navigate } from 'gatsby';

const Index = () => {
	useEffect(() => {
		navigate('/sign-in');
	}, []);

	return null;
};

export default Index;
